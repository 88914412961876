<template>
    <hb-modal v-model="dialog" size="large" title="Update Inventory" height="medium" @content-position="setContentPosition">
        <template v-slot:subheader>
            <span class="hb-text-light">
                Create 2-3 sentences that describes the overview of this form.
            </span>
        </template>
        <template v-slot:content>
            <hb-form label="Product Name">
                <p class="mb-0" v-if="inventoryUpdateSelected.name">{{ inventoryUpdateSelected.name }}</p>
            </hb-form>

            <hb-form label="Description">
                <p class="mb-0" v-if="inventoryUpdateSelected.description">
                    {{ inventoryUpdateSelected.description }}
                </p>
            </hb-form>

            <hb-form label="Product Weight" v-if="inventoryUpdateSelected.weight_unit">
                <p class="mb-0">
                    {{ inventoryUpdateSelected.weight_unit }}: {{ inventoryUpdateSelected.weight }}
                </p>
            </hb-form>

            <hb-form label="Inventory Modifications" full :divider="false" last :content-position="contentPosition">
                <v-row no-gutters ref="inventoryModifications">
                    <div class="hb-text-night-light mb-0 pb-0">
                        Current Inventory: {{ inventoryUpdateSelected.total_inventory ? inventoryUpdateSelected.total_inventory : 0 }}
                    </div>

                    <div class="hb-text-night-light mb-0 pb-0 pl-3">
                        Current Item Cost: ${{ inventoryUpdateSelected.summary_inventory && inventoryUpdateSelected.summary_inventory.average_weighted_cost ? inventoryUpdateSelected.summary_inventory.average_weighted_cost.toFixed(2) : 0 }}
                    </div>
                </v-row>

                <HbSelect
                    :clearable="false"
                    v-model="inventoryModification"
                    :items="modificationsList"
                    v-validate="'required'"
                    data-vv-name="modification"
                    data-vv-as="modification"
                    :error="errors.has('modification')"
                    placeholder="Select Modification"
                    item-text="name"
                    item-value="id"
                    large
                />

                <v-row no-gutters class="pt-2" v-if="inventoryModification === 'newly_added'">
                    <div class="hb-inline-sentence-text mb-0 pb-0">
                        Inventory Added:
                    </div>
                    <hb-text-field
                        v-model="addItemsCount.inventory_count"
                        condensed
                        box
                        small
                        v-validate="'required|max:45|min_value:0'"
                        min="0"
                        type="number"
                        class="no-num-arrows"
                        data-vv-name="inventory_count"
                        data-vv-as="quantity"
                        :error="errors.has('inventory_count')"
                        placeholder="00"
                    />

                    <div class="hb-inline-sentence-text mb-0 pb-0 pl-3">
                        Cost per Item:
                    </div>
                    <hb-text-field
                        v-model="addItemsCount.cost_per_item"
                        condensed
                        box
                        medium
                        @change="fractionalValueEvent($event, 'cost_per_item')"
                        v-validate="'required|max:45|min_value:1'"
                        type="number"
                        class="no-num-arrows"
                        min="0"
                        data-vv-name="cost"
                        :error="errors.has('cost_per_item')"
                        placeholder="00"
                        hide-details
                    >
                    <template v-slot:prepend-inner>
                        $
                    </template>
                </hb-text-field>
                </v-row>

                <span v-if="inventoryModification === 'removing_inventory' || inventoryModification === 'transfer_inventory'">
                    <HbSelect
                        class="pt-4"
                        :clearable="false"
                        v-if="inventoryModification === 'removing_inventory'"
                        v-model="removeInventoryReason"
                        :items="reasonsList"
                        v-validate="'required|max:45'"
                        data-vv-name="remove_type"
                        data-vv-as="reason"
                        :error="errors.has('remove_type')"
                        placeholder="Select reason for removal"
                        item-text="name"
                        item-value="id"
                    />

                    <HbSelect
                        class="pt-4"
                        :clearable="false"
                        v-model="transferProprty"
                        v-if="inventoryModification === 'transfer_inventory'"
                        :items="propertyItems"
                        v-validate="'required'"
                        data-vv-name="transfer_proprty"
                        data-vv-as="proprty"
                        :error="errors.has('transfer_proprty')"
                        placeholder="Select property to transfer"
                        item-text="name"
                        item-value="id"
                    />
    
                    <v-row no-gutters class="pt-3" v-if="removeInventoryReason || transferProprty">
                        <div class="hb-inline-sentence-text mb-0 pb-0">
                            Inventory {{ inventoryModification === 'transfer_inventory' ? 'Transfered' : 'Removed' }}:
                        </div>
                        <hb-text-field
                            v-model="removeItemsCount"
                            condensed
                            box
                            small
                            v-validate="'required|max:45|min_value:0'"
                            min="0"
                            type="number"
                            class="no-num-arrows"
                            data-vv-name="remove_count"
                            data-vv-as="quantity"
                            :error="errors.has('remove_count')"
                            placeholder="00"
                        />
                    </v-row>
    
                    <hb-textarea v-if="removeInventoryReason || transferProprty" v-model="reasonValue" v-validate="'required|max:1000'"
                            data-vv-name="description" data-vv-as="description" :error="errors.has('description')"
                            :placeholder="inventoryModification === 'transfer_inventory' ? 'Provide a description for transferring inventory.' : 'Provide a description for removing inventory.'" :rows="2" width="412px" />
                </span>

            </hb-form>

        </template>

        <template v-slot:right-actions>
            <hb-btn color="primary" :disabled="isLoading($options.name)" :loading="isLoading($options.name)" @click="save">Update</hb-btn>
        </template>
    </hb-modal>
</template>

<script type="text/babel">
import Loader from "../../assets/CircleSpinner.vue";
import api from "../../../assets/api";
import { mapGetters } from 'vuex';
import { notificationMixin } from  '@/mixins/notificationMixin.js';

export default {
    name: 'UpdateInventory',
    mixins: [ notificationMixin ],
    components: {
        Loader
    },
    props: {
        value: {
            type: Boolean
        },
        inventoryUpdateSelected: {
            type: Object,
            default: {}
        },
        propertyId: {
            type: String,
            default: ""
        }
    },
    data() {
        return {
            productData: {
                name: '',
                description: '',
                weight_unit: '',
                weight: ''
            },
            inventoryModification: null,
            modificationsList: [
                { id: 'newly_added', name: 'Adding Inventory'},
                { id: 'removing_inventory', name: 'Removing Inventory'},
                { id: 'transfer_inventory', name: 'Transfer Inventory'}
            ],
            removeInventoryReason: null,
            reasonsList: [
                { id: 'damaged', name: 'Damaged Inventory'},
                { id: 'writeoff', name: 'Write-Off'}
            ],
            contentPosition: '',
            removeItemsCount: '',
            addItemsCount: {
                inventory_count: '',
                cost_per_item: ''
            },
            transferProprty: '',
            reasonValue: '',
        }
    },
    created() {
        this.addItemsCount.cost_per_item = this.inventoryUpdateSelected ? this.inventoryUpdateSelected.default_cost : '';
    },
    computed: {
        ...mapGetters({
            primaryRoleProperties: 'propertiesStore/primaryRoleProperties'
        }),
        propertyItems() {
            let assignedProperties = this.inventoryUpdateSelected.properties.length ? this.inventoryUpdateSelected.properties.map(item => item.id) : [];
            let propertiesList = this.primaryRoleProperties.map(p => {
                return {
                    id: p.id,
                    name: p.name,
                    number: p.number,
                    city: p.city,
                    gds_id: p.gds_id,
                    disabled: false,
                }
            });
            if(assignedProperties.length)
                propertiesList = propertiesList.filter(item => assignedProperties.includes(item.id) && this.propertyId !== item.id)
            return propertiesList;
        },
        dialog: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit("input", value);
            }
        }
    },
    watch: {
        inventoryModification() {
            this.removeInventoryReason = null;
            this.transferProprty = '';
        }
    },
    methods: {
        fractionalValueEvent(params,field) {
            this.addItemsCount[field] = parseFloat(params).toFixed(2);
        },
        resetData() {
            this.productData = {
                name: '',
                description: '',
                weight_unit: '',
                weight: ''
            }
        },
        async save() {
            const validationStatus = await this.$validator.validateAll();
            if (validationStatus) {
                try {
                    let path = api.PROPERTIES + (this.propertyId) + `/products/${this.inventoryUpdateSelected.id}/inventory-functions`;
                    let payload = {
                        "quantity": parseInt(this.addItemsCount.inventory_count),
                        "cost_per_item": parseFloat(this.addItemsCount.cost_per_item),
                        "transaction_type": this.inventoryModification === 'newly_added' ? 'newly_added' : this.removeInventoryReason,
                        "reason": this.reasonValue
                    }
                    if(this.inventoryModification !== 'newly_added') {
                        delete payload.cost_per_item;
                        payload.quantity = parseInt(this.removeItemsCount);
                    }
                    else 
                        delete payload.reason;

                    if (this.inventoryModification === 'transfer_inventory') {
                        path = path + '/transfer';
                        delete payload.transaction_type;
                        payload['transfer_to_property_id'] = this.transferProprty;
                    }
                    let response = await api.put(this, path, payload);
                    this.$emit('fetchData');
                    this.dialog = false;
                } catch (error) {
                    this.showMessageNotification({ description: error });
                    this.errors.clear();
                }
            }
        },
        setContentPosition(e){
            this.contentPosition = e;
        },

    }
}
</script>

<style lang="scss" scoped>
.inline-flex {
    display: inline-flex !important;
}

.left-border {
    border-left: 1px solid #ccc;
    /* Adjust color and thickness as needed */
}

.display-none {
    display: none;
}

.modal-form-bg-color {
    background: #F4F6F8;
}

.font-weight-500 {
    font-weight: 500;
}

/* Add styles for preview section */
.preview {
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
}

.preview-img {
    margin-right: 10px;
    position: relative;
    width: 100%;
}

.preview img {
    max-width: 100%;
    max-height: 100%;
}

.remove-button {
    position: absolute;
    top: 5px;
    right: 5px;
}

/* Rest of the styles remain the same */
.image-upload {
    border: 2px dashed #ccc;
    border-radius: 4px;
    padding: 20px;
    text-align: center;
    cursor: pointer;
    position: relative;
}

.image-upload input[type="file"] {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
}

.uploading {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
}

.upload-content {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.icon-upload {
    font-size: 48px;
    color: #ccc;
    margin-bottom: 10px;
}

.click-to-upload {
    color: blue;
    text-decoration: underline;
    cursor: pointer;
}

.preview-img-border {
    border: 1px solid #DFE3E8;
    border-radius: 4px;
    margin: 0;
}

.flex-content {
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.no-data-box {
    padding: 30px 0;
    text-align: center;
    border: 1.5px dashed #ccc;
    border-radius: 3px;
}

.display-flex {
    display: flex;
}
</style>

<style lang="scss">
.merchandise-drawer .v-navigation-drawer__content .main-content-box .hb-aviary-form-wrapper,
.merchandise-drawer .v-navigation-drawer__content .main-content-box .hb-aviary-form-wrapper .no-gutters
{
    height: 100%;
}
</style>
